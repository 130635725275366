.App {
  text-align: center;
  width: 100vw;
  height: 100vh;

  background-color: #232323;
}

span {
  font-size: 22px;
  color: white;
}

img {
  height: 80%;
}

.square {
  /* grid-column: 1; */
  /* --max-size: 400px;
  height: 33%;
  width: 33%;
  max-width: 200px;
  max-height: 200px; */
  /* background-color: #61dafb; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  border: 2px solid #61dafb;
  box-sizing: border-box;
}

.container {
  display: grid;
  /* width: 600px;
  height: 600px; */
  grid-template-columns: repeat(3, 70px);
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 0.5em;
  gap: 1em;
  grid-template-rows: 70px 70px 70px;
  /* max-width: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  /* gap: 2px; */
}